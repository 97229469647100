<template>
  <vue-good-table
    v-bind="$attrs"
    v-on="$listeners"
    :rows="rows"
    :mode="enableRemoteMode()"
    :columns="!emptyRows() ? fullColumns : []"
    :styleClass="!unclickable ? 'vgt-table' : 'vgt-table disabled'"
    :search-options="enableSearchTable()"
    :pagination-options="{
      enabled: enablePagination(),
      perPage: this.rowsPerPage,
    }"
    :sort-options="{ enabled: false }"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    ref="hccTable"
  >
    <div slot="emptystate" class="empty-rows">
      <div class="empty-rows__container">
        <img
          :src="require('@/assets/images/paper.svg')"
          class="empty-rows__icon"
        />
      </div>
      <span class="empty-rows__text">{{ emptyText }}</span>
    </div>
    <div slot="table-actions" class="table-actions" v-if="showTableActions">
      <span v-if="title" :title="title" class="table__title">{{ title }}</span>
      <div class="table-actions__items" v-if="showTableActionsItems">
        <slot name="table-filter" />
        <hcc-input
          v-if="showSearchInput"
          :placeholder="search"
          :disabled="disabledSearchByService"
          v-model.trim="searchValue"
          @input="$emit('search', { value: searchValue })"
          @keyup.enter="$emit('search', { value: searchValue, key: true })"
          ><template #icon><magnify-icon /></template
        ></hcc-input>
        <hcc-button
          v-if="showCollapseButton"
          class="table__button"
          variant="outline"
          color="primary"
          @click="toggleCollapseRows"
        >
          {{ collapsableText }}
        </hcc-button>
        <hcc-button
          class="table__button"
          v-if="$listeners.add"
          @click="$emit('add')"
          :disabled="!enableAdd"
        >
          <div>
            <component
              v-if="buttonIcon"
              :is="buttonIcon"
              class="button--icon"
            />
            <span v-if="!buttonText">{{ $t("common.add") }}</span>
            <span v-else>{{ buttonText }}</span>
          </div>
        </hcc-button>
      </div>
      <div class="table-actions__items" v-if="isSchedule">
        <slot name="table-no-actions" />
      </div>
    </div>
    <template slot="table-row" slot-scope="props">
      <slot name="table-row" v-bind="props">
        <span v-if="props.column != 'actions'">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </slot>
      <span v-if="props.column.field == 'actions'">
        <div v-if="showActions($listeners)">
          <div v-if="!props.row.editable">
            <div v-if="overrideActions(props.row)">
              <hcc-button-icon
                v-tooltip="$t('shared-components.table.edit-icon')"
                v-if="$listeners.edit && !$listeners.editSubgroup"
                @click="$emit('edit', props.row)"
                :disabled="unclickable"
                ><pencil-icon
              /></hcc-button-icon>
              <hcc-button-icon
                v-tooltip="$t('shared-components.table.edit-subgroup')"
                v-else-if="$listeners.editSubgroup"
                @click="toggleEditRow(props.row)"
                :disabled="unclickable"
                ><pencil-icon
              /></hcc-button-icon>
              <hcc-button-icon
                v-tooltip="$t('shared-components.table.delete-icon')"
                v-if="$listeners.delete && !$listeners.deleteSubgroup"
                @click="$emit('delete', props.row)"
                :disabled="unclickable"
                ><delete-icon
              /></hcc-button-icon>
              <hcc-button-icon
                v-tooltip="$t('shared-components.table.delete-subgroup')"
                v-else-if="$listeners.deleteSubgroup"
                @click="$emit('deleteSubgroup', props.row)"
                :disabled="unclickable"
                ><delete-icon
              /></hcc-button-icon>
              <template v-if="$listeners.preview">
                <hcc-button-icon
                  v-if="!props.row.categoria || props.row.categoria.value !== 'Ecommerce'"
                  @click="$emit('preview', props.row)"
                  :disabled="unclickable"
                  v-tooltip="tableTexts['preview-icon']"
                  >
                  <eye-icon
                /></hcc-button-icon>
              </template>
              <template v-if="$listeners.calendar">
              <hcc-button-icon
                 v-if="!props.row.categoria || props.row.categoria.value !== 'Ecommerce'"
                @click="$emit('calendar', props.row)"
                :disabled="unclickable"
                v-tooltip="tableTexts['calendar-today-icon']"
                >
                <calendar-today-icon
              /></hcc-button-icon>
              </template>
              <hcc-button-icon
                v-if="$listeners.download"
                @click="$emit('download', props.row)"
                :disabled="unclickable"
                ><download-icon
              /></hcc-button-icon>
              <hcc-button-icon
                v-if="$listeners.excel"
                @click="$emit('excel', props.row)"
                :disabled="unclickable"
                v-tooltip="tableTexts['report-icon']"
                ><file-excel
              /></hcc-button-icon>
              <hcc-button-icon
                v-if="$listeners.copy"
                :disabled="unclickable"
                v-clipboard:copy="messageToCopy"
                v-tooltip="tableTexts['copy-icon']"
                @click="$emit('copy', props.row)"
                ><copy-icon
              /></hcc-button-icon>
            </div>
            <div v-else>
              <hcc-button-icon disabled unclickable
                ><pencil-off-icon
              /></hcc-button-icon>
            </div>
          </div>
          <div v-else>
            <hcc-button-icon
              v-tooltip="$t('shared-components.table.accept')"
              :muted="false"
              color="alert-green"
              @click="$emit('editSubgroup', props.row)"
              :disabled="unclickable"
              ><check-icon
            /></hcc-button-icon>
            <hcc-button-icon
              v-tooltip="$t('shared-components.table.close')"
              :muted="false"
              color="alert"
              @click="toggleEditRow(props.row)"
              :disabled="unclickable"
              ><window-close-icon
            /></hcc-button-icon>
          </div>
        </div>
      </span>
    </template>
    <template slot="table-header-row" slot-scope="props">
      <span v-if="props.column.field == 'actions'">
        <hcc-button-icon
          v-tooltip="$t('shared-components.table.edit-icon')"
          v-if="$listeners.edit"
          @click="$emit('edit', props.row)"
          :disabled="unclickable"
          ><pencil-icon
        /></hcc-button-icon>
        <hcc-button-icon
          v-tooltip="$t('shared-components.table.delete-icon')"
          v-if="$listeners.delete"
          @click="$emit('delete', props.row)"
          :disabled="unclickable"
          ><delete-icon
        /></hcc-button-icon>
        <hcc-button-icon
          v-tooltip="$t('shared-components.table.add-subgroup')"
          v-if="$listeners.addSubgroup"
          @click="addSubgroup(props.row)"
          :disabled="unclickable"
          ><plus-circle-icon
        /></hcc-button-icon>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <template slot="pagination-bottom" slot-scope="props">
      <hcc-pagination
        :total="totalPages ? totalPages : props.total"
        :rowsPerPage="rowsPerPage"
        :actualPage="actualPage"
        :pageChanged="props.pageChanged"
        @page-change="newPage"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import HccInput from '../HccInput/index.vue';
import HccButton from '../HccButton/index.vue';
import HccButtonIcon from '../HccButtonIcon/index.vue';
import HccPagination from './HccPagination.vue';

export default {
  components: {
    VueGoodTable,
    HccInput,
    HccButton,
    HccButtonIcon,
    HccPagination,
  },
  props: {
    title: {
      type: String,
    },
    filters: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    rowsPerPage: {
      type: Number,
      default: 5,
    },
    actualPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    enableAdd: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
    },
    buttonIcon: {
      type: String,
      default: 'plus-circle-icon',
    },
    unclickable: {
      type: Boolean,
      default: false,
    },
    overrideActions: {
      type: Function,
      default: () => true,
    },
    isSchedule: {
      type: Boolean,
      default: false,
    },
    emptyRowsTable: {
      type: Boolean,
      default: false,
    },
    disabledSearchByService: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: String,
    messageToCopy: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchValue: '',
      editableRow: null,
      currentPage: this.initialPage,
      expanded: false,
    };
  },
  computed: {
    fullColumns() {
      return this.showActions(this.$listeners) ? [...this.columns, {
        label: this.$t('shared-components.table.actions'),
        field: 'actions',
        sortable: false,
        thClass: 'custom-th-class',
        tdClass: 'custom-td-class',
      }] : this.columns;
    },
    showTableActions() {
      return this.title
      || this.$listeners.search
      || this.enableSearch
      || this.$listeners.add;
    },
    showTableActionsItems() {
      return this.$listeners.search
      || this.enableSearch
      || this.$listeners.add;
    },
    showSearchInput() {
      return (this.$listeners.search
        || this.enableSearch);
    },
    add() {
      return this.$t('shared-components.table.add');
    },
    search() {
      return !this.searchPlaceholder ? this.$t('common.search') : this.searchPlaceholder;
    },
    emptyText() {
      return this.$t('shared-components.table.empty');
    },
    collapsableText() {
      return this.expanded ? this.$t('shared-components.table.collapse-all') : this.$t('shared-components.table.expand-all');
    },
    showCollapseButton() {
      return (this.$listeners.addSubgroup || this.$listeners.editSubgroup)
          && this.rows.length > 0;
    },
    tableTexts() {
      return this.$t('shared-components.table');
    },
  },
  watch: {
    rows: {
      deep: true,
      handler(newRows) {
        this.editableRow = newRows
          .every(row => !row.editable && !!row.children && row.children
            .every(r => !r.editable)) ? null : this.editableRow;
      },
    },
  },
  methods: {
    emptyRows() {
      return this.rows.length === 0;
    },
    showActions(listeners) {
      const eventListeners = Object.keys(listeners).filter(
        listener => (listener !== 'add' && listener !== 'search' && listener !== 'pageChanged' && listener !== 'sortData'),
      ).length;

      return eventListeners;
    },
    enablePagination() {
      return this.emptyRows() ? false : !!this.$props.pagination;
    },
    enableSearchTable() {
      return this.enableSearch ? { enabled: true, externalQuery: this.searchValue }
        : { enabled: false };
    },
    enableRemoteMode() {
      return this.remote ? 'remote' : '';
    },
    toggleEditRow(row) {
      if (row.editable) {
        Object.assign(row, this.editableRow);
        // eslint-disable-next-line no-param-reassign
        row.editable = false;
        this.editableRow = null;
      } else if (this.editableRow === null) {
        // eslint-disable-next-line no-param-reassign
        row.editable = true;
        this.editableRow = JSON.parse(JSON.stringify(row));
      }
    },
    onSortChange(params) {
      this.$emit('sortData', params);
    },
    onPageChange(params) {
      this.$emit('changePage', params);
    },
    newPage(newPage) {
      this.$emit('pageChanged', newPage);
    },
    addSubgroup(row) {
      this.$emit('addSubgroup', row);
      this.$refs.hccTable.expandAll();
    },
    toggleCollapseRows() {
      if (this.expanded) {
        this.expanded = false;
        this.$refs.hccTable.collapseAll();
      } else {
        this.expanded = true;
        this.$refs.hccTable.expandAll();
      }
    },

  },
};
</script>
